import './home.scss';
import banner from '../../assets/images/banner.png'
import promodePic from '../../assets/images/promod.svg'
import promodeSidePic from '../../assets/images/promod-side-face-pic.svg'
import followIcon from '../../assets/images/follow-icon.svg'
import tallModernBuilding from '../../assets/images/tall-modern-building.svg'
import carLogo from '../../assets/images/car-logo.png'
import cbwLogo from '../../assets/images/cbw-logo.png'
import apexDefiLabs from '../../assets/images/apex-defi-labs.png'
import defiAssets from '../../assets/images/defi-assets.png'
import webIcon from '../../assets/images/web.svg'
import facebookIcon from '../../assets/images/facebook-icon.svg'
import linkedinIcon from '../../assets/images/linkedin-icon.svg'
import twitterIcon from '../../assets/images/twitter-icon.svg'
import linkedinFillIcon from '../../assets/images/linkedin-full-icon.png'
import mrPromodeMetHon from '../../assets/images/mr-promode-met-hon.png'
import pramodThumbnail from '../../assets/images/pramod-thumbnail.png'
import pramodSlider from '../../assets/images/pramod-slider.png'
import sliderImg5 from '../../assets/images/slider-img5.png'
import newSliderImg1 from '../../assets/images/new-img/Food-Drive-2022_1.png'
import newSliderImg2 from '../../assets/images/new-img/slider-img2.png'
import newSliderImg3 from '../../assets/images/new-img/slider-img3.png'
import newSliderImg4 from '../../assets/images/new-img/slider-img4.png'
import newSliderImg7 from '../../assets/images/new-img/slider-img7.png'
import speakerAtBiggestCapital from '../../assets/images/speaker-at-biggest-capital.png'
import meetingWithAssemblyman from '../../assets/images/meeting-with-assemblyman.png'
import meetPromodAttarde from '../../assets/videos/Meet-Pramod-Attarde.mp4'
import iotBanner from '../../assets/images/global-business-internet-network-connection-iot.svg'

import Slider from "react-slick";

function Home() {

    var settings = {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "200px",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    const redirectToApexDefi = () => {
        window.open('https://apexdefilabs.com/', '_blank');
    }
    const redirectToDefiAssets = () => {
        window.open('https://defiassetsllc.com/', '_blank');
    }
    const redirectToCryptoAssetrating = () => {
        window.open('https://cryptoassetratings.com/', '_blank');
    }
    const redirectToCbw = () => {
        window.open('https://cryptobusinessworld.com/', '_blank');
    }
    const redirectToFacebook = () => {
        window.open('https://www.facebook.com/pramod.attarde.3', '_blank');
    }
    const redirectToLinkedin = () => {
        window.open('https://www.linkedin.com/in/pramodblockchain/', '_blank');
    }
    const redirectToTwitter = () => {
        window.open('https://twitter.com/AttardePramod', '_blank');
    }
    return (
        <div className="home">
            <div className="home__top">
                <img className="home__top__banner" src={banner}></img>
                <img className="home__top__promod" src={promodePic}></img>
                <div className="schedule-meeting">
                <button className="schedule-meeting__button">Schedule Meeting with Pramod</button>
                <div className="schedule-meeting__options">
                    <a href="https://calendly.com/pramoda-apexdefilabs/2nd-aug-business-opportunity-with-pramod-attarde" target="_blank">2nd August</a>
                    <a href="https://calendly.com/pramoda-apexdefilabs/3rd-aug-business-opportunity-with-pramod-attarde" target="_blank">3rd August</a>
                    <a href="https://calendly.com/pramoda-apexdefilabs/4th-aug-business-opportunity-with-pramod-attarde" target="_blank">4th August</a>
                    <a href="https://calendly.com/pramoda-apexdefilabs/5th-aug-business-opportunity-with-pramod-attarde" target="_blank">5th August </a>
                </div>
                </div>
            </div>
            <div className="home__about">
                <span className="home__about__title">ABOUT</span>
                <div className="home__about__description">
                    <span>
                        Pramod Attarde serves as the CEO of Apex Defi Labs Inc., an innovative FINTECH firm headquartered in New York. In this role, he spearheads digital innovation, challenging traditional norms within Global Capital Markets.
                    </span>
                    <span>
                        Renowned for his dynamic speaking prowess, Pramod has the remarkable ability to elucidate intricate technological concepts for non-technical audiences. With a wealth of 28+ years in the tech entrepreneurship realm, he passionately strives to revolutionize the Global Capital Market, making it more accessible for Small and Medium Businesses.
                    </span>
                    <span>
                        His prominence as a speaker is evident as he was invited to address one of the largest capital market conferences globally, held in Las Vegas. At this distinguished event, attended by over 9000 finance professionals across three days, Pramod shared his insights. Recently, he had the privilege to speak at the Government Blockchain Association in Washington DC, engaging in discussions centered around the "Future of Money." His commitment to spreading knowledge on the democratization of capital markets and the transformative power of blockchain technology is evidenced by his engagements at various chambers of commerce, educational institutions, and business gatherings.
                    </span>
                </div>
            </div>
            <div className="home__bio">
                <img className="home__bio__iot-banner" src={iotBanner}></img>
                <div className="home__bio__card">
                    <img className="home__bio__card__pic" src={promodeSidePic}></img>
                    <span className="home__bio__card__description">
                        <b>Pramod</b> extends his expertise by offering <b>consultation to executives, trade associations, governments, and investors in the United States</b>, particularly on the subject of <b>blockchain technology.</b> Notably, he actively contributes to the legislative landscape by assisting the State of New Jersey in drafting essential bills.
                    </span>
                    <span className="home__bio__card__description">
                        Beyond his professional endeavors, Pramod is the visionary behind the Non-Profit New Jersey Blockchain Council, an entity dedicated to disseminating education and awareness about Blockchain Technology. Additionally, he serves as the <b>Director of the "Attarde Foundation."</b> During the second wave of the pandemic, the Attarde Family, through the foundation, generously donated ventilators and oxygen concentrators to India. The foundation's impact has grown exponentially, reaching more than 15,000 individuals in India. Remarkably, the <b>Attarde Foundation is officially recognized as a Certifying Organization for the US President's Volunteer Service Award.</b>
                    </span>
                </div>
                <span className="home__bio__follow-me" onClick={redirectToLinkedin}>
                    <span className="home__bio__follow-me__text">Follow me</span>
                    <img className="home__bio__follow-me__icon" src={followIcon}></img>
                </span>
                <div className='home__bio__linkedin-badge'>
                    <div className='home__bio__linkedin-badge__head'>
                        <img className="home__bio__linkedin-badge__head__pic" src={linkedinFillIcon}></img>
                    </div>
                    <div className='home__bio__linkedin-badge__body'>
                        <img className="home__bio__linkedin-badge__body__pic" src={promodePic}></img>
                        <span className='home__bio__linkedin-badge__body__name'>Pramod A.</span>
                        <span className='home__bio__linkedin-badge__body__description'>Transforming Global Capital Market, Speaker, Innovation Evangelist, Defi, Web3</span>
                        <span className='home__bio__linkedin-badge__body__comp-name'>Apex Defi Labs</span>
                        <span className='home__bio__linkedin-badge__body__view-profile' onClick={redirectToLinkedin}>View profile</span>
                    </div>
                </div>
            </div>
            <div className="home__video">
                <video className="home__video__video" controls poster={pramodThumbnail}>
                    <source src={meetPromodAttarde} type="video/mp4" />
                </video>
                <div className="home__video__text">
                    <span className="home__video__text__title">Meet Pramod Attarde: Transforming Global Capital Markets and Blockchain Innovation</span>
                    <span className="home__video__text__description">
                        In this captivating video, we introduce you to Pramod Attarde, a visionary leader who is making waves in the world of finance and blockchain technology. Pramod serves as the CEO of Apex Defi Labs Inc., a forward-thinking FINTECH firm headquartered in the heart of New York City.
                    </span>
                </div>
            </div>
            <div className="home__our-companies">
                <img className="home__our-companies__banner" src={tallModernBuilding}></img>
                <div className="home__our-companies__content">
                    <span className="home__our-companies__content__head">OUR COMPANIES</span>
                    <div className="card-deck home__our-companies__content__companies">
                        <div className="card home__our-companies__content__companies__card">
                            <img src={apexDefiLabs} className="home__our-companies__content__companies__card__img" alt="Card image cap" />
                            <div className="card-body card home__our-companies__content__companies__card__card-body">
                                <span className="home__our-companies__content__companies__card__card-body__description">A premier destination for cutting-edge decentralized finance solutions. At Apex Defi Labs, we are passionate about leveraging the power of blockchain technology to drive innovation and revolutionize the financial industry. Our team of experienced professionals brings together a wealth of knowledge in blockchain development, finance, and investment to create innovative, secure, and transparent financial products and services for our clients.</span>
                                <span className="home__our-companies__content__companies__card__card-body__btn" onClick={redirectToApexDefi}>
                                    <img className="home__our-companies__content__companies__card__card-body__btn__icon" src={webIcon}></img>
                                    <span className="home__our-companies__content__companies__card__card-body__btn__text">Visit website</span>
                                </span>
                            </div>
                        </div>
                        <div className="card home__our-companies__content__companies__card">
                            <img src={defiAssets} className="home__our-companies__content__companies__card__img" alt="Card image cap" />
                            <div className="card-body card home__our-companies__content__companies__card__card-body">
                                <span className="home__our-companies__content__companies__card__card-body__description">At DeFi Assets LLC, our expertise reflects in the tokenization of real-world assets, offering businesses the opportunity to convert their tangible assets into digital tokens. Through our innovative tokenization solutions, we enable the unlocking of liquidity, enhance accessibility, and facilitate fractional ownership of assets. Our goal is to revolutionize the way assets are represented and traded, providing greater flexibility and opportunities for businesses and investors alike.</span>
                                <span className="home__our-companies__content__companies__card__card-body__btn" onClick={redirectToDefiAssets}>
                                    <img className="home__our-companies__content__companies__card__card-body__btn__icon" src={webIcon}></img>
                                    <span className="home__our-companies__content__companies__card__card-body__btn__text">Visit website</span>
                                </span>
                            </div>
                        </div>
                        <div className="card home__our-companies__content__companies__card">
                            <img src={carLogo} className="home__our-companies__content__companies__card__img" alt="Card image cap" />
                            <div className="card-body card home__our-companies__content__companies__card__card-body">
                                <span className="home__our-companies__content__companies__card__card-body__description">We provide investors with valuable insights into the quality, risk, and potential of crypto assets through our comprehensive and dependable rating services. Our aim is to equip investors with the necessary tools to make informed decisions in the ever-changing crypto market. With our rating services, investors can confidently navigate the dynamic landscape of cryptocurrencies and make sound investment choices.</span>
                                <span className="home__our-companies__content__companies__card__card-body__btn" onClick={redirectToCryptoAssetrating}>
                                    <img className="home__our-companies__content__companies__card__card-body__btn__icon" src={webIcon}></img>
                                    <span className="home__our-companies__content__companies__card__card-body__btn__text">Visit website</span>
                                </span>
                            </div>
                        </div>
                        <div className="card home__our-companies__content__companies__card">
                            <img src={cbwLogo} className="home__our-companies__content__companies__card__img" alt="Card image cap" />
                            <div className="card-body card home__our-companies__content__companies__card__card-body">
                                <span className="home__our-companies__content__companies__card__card-body__description">Stay up to date with all the latest developments in the world of cryptocurrencies and blockchain technology through our dedicated news platform, Crypto Business World. Our comprehensive articles, interviews, and industry coverage ensure that you are well-informed about the happenings in this rapidly evolving space. We are committed to delivering timely and relevant news that keeps you in the loop and empowers you to stay ahead.</span>
                                <span className="home__our-companies__content__companies__card__card-body__btn" onClick={redirectToCbw}>
                                    <img className="home__our-companies__content__companies__card__card-body__btn__icon" src={webIcon}></img>
                                    <span className="home__our-companies__content__companies__card__card-body__btn__text">Visit website</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home__achievements">
                <div className="home__achievements__left">
                    <span className="home__achievements__left__some-of-his">Some of his</span>
                    <span className="home__achievements__left__achievements">Achievents</span>
                    <span className="home__achievements__left__description">Pramod Attarde, as CEO of Apex Defi Labs Inc., leads fintech innovation in global capital markets. With over 28 years in tech entrepreneurship, his dynamic communication skills simplify intricate technological concepts.</span>
                    <span className="home__achievements__left__description">Beyond corporate success, Pramod's commitment to social impact is evident through the Attarde Foundation and the Non-Profit New Jersey Blockchain Council, which he founded. During the pandemic, the foundation generously donated ventilators and oxygen concentrators, impacting 15,000 individuals in India. It's officially recognized for the US President's Volunteer Service Award. Pramod's multifaceted roles as a CEO, philanthropist, and blockchain advocate leave a lasting mark on industries and communities alike.</span>
                    <div className="home__achievements__left__social-media">
                        <span className="home__achievements__left__social-media__text">Follow me</span>
                        <span className="home__achievements__left__social-media__icons">
                            <img className="home__achievements__left__social-media__icons__icon" onClick={redirectToFacebook} src={facebookIcon}></img>
                            <img className="home__achievements__left__social-media__icons__icon" onClick={redirectToLinkedin} src={linkedinIcon}></img>
                            <img className="home__achievements__left__social-media__icons__icon" onClick={redirectToTwitter} src={twitterIcon}></img>
                        </span>
                    </div>
                </div>
                <div className="home__achievements__right">
                    <div className="col-md-8 m-l-auto">
                        <Slider {...settings}>
                            <div className="card home__achievements__right__card h-100">
                                <img src={speakerAtBiggestCapital} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Speaker at Biggest Capital Market Conference “SFVegas” in 2020 at Las Vegas</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card h-100">
                                <img src={meetingWithAssemblyman} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Meeting with Assemblyman Robert Korbenchik, New Jersey at Conference explained on Blockchain.</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card h-100">
                                <img src={mrPromodeMetHon} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Mr. Pramod met Hon. Gadakariji during his visit to India to discussing about Blockchain.</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card h-100">
                                <img src={pramodSlider} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Mr.Pramod as a Speaker for The Future of Money, Governance, and the Law at Government Blockchain Association,  Washington DC - 2023</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card h-100">
                                <img src={newSliderImg2} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Mr.Pramod with Mr.Ragunath Mashelkar at Garje Marathi Global Excellence Summit 2023</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card h-100">
                                <img src={newSliderImg3} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">At the Vegas Conference with US Congressman Raja Krishnamoorthi in Washington, DC</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card h-100">
                                <img src={newSliderImg4} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">At the GMG Conference with the US Congressman, Shri Thanedar</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card h-100">
                                <img src={sliderImg5} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Speaker at the Government Blockchain Association in Washington, DC</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card h-100">
                                <img src={newSliderImg1} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Food Donation from the Attarde Foundation
                                </span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card h-100">
                                <img src={newSliderImg7} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Attarde Foundation generously donated ventilator and oxygen concentrators in India during COVID second wave</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                        </Slider>
                    </div >
                </div>
            </div>
        </div>
    );
}

export default Home;
